<template>
  <div>
  <!-- <navbar></navbar> -->
   <!-- <employee></employee>-->
    <main class="py-4">
      <slot></slot>
    </main>
  </div>
</template>
<script>
//import navbar from "./components/Navbar";
//import employee from "./components/Employee";
export default {
  components: {
    //navbar,
   // employee
  }
};
</script>